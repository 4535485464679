<template>
  <div>
    <v-form>
      <p class="mb-3">
        This experiment focuses on the esterification of 1-phenylethanol with propionic anhydride in
        the presence of a stereoselective catalyst,
        <stemble-latex content="$\text{(}S\text{)-HBTM}$" />
        or
        <stemble-latex content="$\text{(}R\text{)-HBTM.}$" />
        Consider the statements below regarding the TLC plates to be run in this experiment. Select
        <b>ALL</b> correct statements.
      </p>

      <p v-for="option in optionsShuffled" :key="'pt-1-' + option.value" class="mb-2">
        <v-checkbox v-model="inputs.studentAnswers" :value="option.value" class="pl-0 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI_51LPL6_Q6',
  components: {ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
      },
      options: [
        {
          text: 'The product (ester) has a greater $R_f$ value than the alcohol reagent',
          value: 'Rf',
        },
        {
          text: 'A comparison of the relative areas of the spots corresponding to the ester and the unreacted 1-phenylethanol can provide an estimate of the percent yield of the reaction.',
          value: 'areas',
        },
        {
          text: 'The stationary phase on the TLC plate is non-toxic, so you are free to handle the plates near your face with bare hands',
          value: 'bareHands',
        },
        {
          text: 'Phosphomolybic acid is used to stain the spots on the TLC plate green',
          value: 'stain',
        },
        {
          text: 'The samples in the two lanes on the TLC plate should be spotted as close as possible to one another to allow for a better analysis.',
          value: 'spotting',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
